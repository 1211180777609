<template>
  <!-- Modal -->
  <b-modal id="answer-modal"
           @shown="doShownModal"
           @hidden="resetModal"
           :no-close-on-backdrop="loading">
    <template v-slot:modal-header>
      <b-badge :class="'badge-' + ticket.status.toLowerCase()">{{
        ticket.status.toLowerCase()
      }}</b-badge>
      <div class="d-flex w-100"
           :disabled="loading || submitting">
        <div class="mt-3">
          <div class="d-flex">
            <div class="text-right">
              <span class="text-muted">{{$ml.get('ticketNumber')}}:</span>
            </div>
            <div class="ml-2"> <span class="ticketId">{{ ticket.id }}</span>
            </div>
          </div>
        </div>
        <b-dropdown variant="light-primary"
                    class="ml-auto btn-icon more-dropdown"
                    right
                    v-if="!isClosed">
          <template v-slot:button-content><i class="ki ki-more-hor p-0"></i>
          </template>
          <b-dropdown-item @click="closeTicket"
                           class="text-danger">{{$ml.get('closeTicket')}}</b-dropdown-item>
        </b-dropdown>

        <b-button variant="light"
                  class="btn-icon rounded-sm"
                  :class="isClosed?'ml-auto':'ml-2'"><i class="ki ki-long-arrow-down down-icon"></i></b-button>
        <b-button variant="danger"
                  class="ml-2 mr-n4 btn-icon btn-light rounded-sm"
                  @click="$bvModal.hide('answer-modal')"><i class="far fa-times-circle text-danger"></i></b-button>
      </div>
      <div v-if="ticketAttachments.length">
        <i class="fas fa-paperclip"></i>:
        <span v-for="attachment in ticketAttachments"
              :key="attachment.id"><a :href="attachmentUrl + attachment.url"
             target="_blank"><i class="la"
               :class="'la-file-' + getFileType(attachment)"></i></a></span>
      </div>
    </template>

    <content-loader v-if="loading">
      <slot>
        <rect x="54"
              y="4"
              width="88"
              height="12"
              rx="3" />
        <rect x="54"
              y="24"
              width="52"
              height="12"
              rx="3" />
        <rect x="0"
              y="56"
              width="400"
              height="12"
              rx="3" />
        <rect x="0"
              y="76"
              width="370"
              height="12"
              rx="3" />
        <rect x="0"
              y="96"
              width="178"
              height="12"
              rx="3" />
        <circle cx="22"
                cy="22"
                r="22" />
      </slot>
    </content-loader>

    <template v-else>

      <div class="chat d-flex">
        <div class="avatar order-2">
          <img class="img-fluid"
               width="80"
               height="80"
               :src="ticket.creator && ticket.creator.data ? ticket.creator.data.picture : ''"
               alt />
        </div>
        <div class="talk order-1">
          <div class="bubble-blue"
               v-html="ticket.body"></div>
          <div class="time">
            <span>{{ ticket.creator && ticket.creator.data ? ticket.creator.data.fullname : '' }}</span>
            <span> - </span>
            <span>  {{$customFunctions.setMomentWithFormat(ticket.created_at,'L LT')}}</span>
          </div>
        </div>
      </div>

      <hr />

      <div v-if="!answers.length"
           class="text-muted">
        <div v-if="!isClosed">{{$ml.get('writeSomething')}}</div>
      </div>

      <template v-else>
        <div v-for="answer in answers"
             :key="answer.id"
             class="chat d-flex">
          <div class="avatar"
               :class="isUserAnswer(answer) ? 'order-2' : 'order-1'">
            <img class="img-fluid"
                 width="80"
                 height="80"
                 :src="answer.owner.data.picture"
                 alt />
          </div>
          <div class="talk"
               :class="isUserAnswer(answer) ? 'order-1' : 'order-2'">
            <div :class="isUserAnswer(answer) ? 'bubble-blue' : 'bubble'"
                 v-html="answer.body">
            </div>
            <div class="time">
              <span>{{ answer.owner && answer.owner.data ? answer.owner.data.fullname : '' }}</span>
              <span> - </span> 
            <span>  {{$customFunctions.setMomentWithFormat(answer.created_at,'L LT')}}</span>
            </div>
          </div>
        </div>
      </template>
    </template>

    <template v-slot:modal-footer>
      <div class="d-flex w-100 align-items-end"
           :disabled="loading || submitting"
           v-if="!isClosed">
        <div for="attachment"
             class="mb-2">
          <div class="btn btn-custom-primary btn-icon rounded-sm ml-auto">
            <i class="fas fa-paperclip"></i>
          </div>
          <b-form-file id="attachment"
                       v-model="files"
                       accept="image/*, application/pdf"
                       multiple
                       hidden>
          </b-form-file>
        </div>
        <div class="flex-grow-1 mx-4">
          <b-textarea v-autogrow
                      class="custom-txtarea"
                      v-model="text"
                      :placeholder="$ml.get('writeReplyOrDragFilesHere')">></b-textarea>
        </div>
        <b-button ref="submit_button"
                  class="mb-2"
                  variant="primary"
                  @click="submitAnswer">
          {{$ml.get('send')}}
        </b-button>
      </div>
      <div class="d-block w-100"
           v-if="files.length">
        <div class="small"
             v-for="(file, index) in files"
             :key="index">
          <i class="fas fa-paperclip fa-1x"></i> {{ file ? file.name : '' }}
          <div class="float-right cursor-pointer"
               @click="files.splice(index, 1)">
            <i class="fas fa-trash fa-1x text-danger"></i>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import { ContentLoader } from 'vue-content-loader'
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive'

export default {
  name: 'TicketAnswerModal',
  props: ['ticket'],
  data() {
    return {
      loading: false,
      submitting: false,
      text: '',
      files: [],
      answers: [],
      attachmentUrl: 'http://apiv2.yenibosna.plusclouds/',
      isClosed: false,
    }
  },
  methods: {
    doShownModal() {
      if (this.ticket.answer_count > 0) {
        this.getTicketAnswers(this.ticket.id)
          .then((res) => {
            this.setTicketAnswers(res)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    resetModal() {
      this.answers = []
      this.text = ''
      this.files = []
    },
    getTicketAnswers(id) {
      this.loading = true
      return new Promise((resolve, reject) => {
        ApiService.get(`support/${id}/answers?position=id|desc`)
          .then(({ data }) => {
            this.loading = false
            resolve(data)
          })
          .catch((err) => {
            this.loading = false
            reject(err)
          })
      })
    },
    setTicketAnswers(res) {
      // Check url in text
      res.data.map((item) => {
        item.body = this.$urlify(item.body)
      })

      this.answers = res.data.reverse()
    },
    submitAnswer() {
      if (this.text != '') {
        this.submitting = true

        const submitButton = this.$refs['submit_button']
        this.$spinnerLoader.addSpinnerLoader(submitButton)

        let formData = new FormData()
        formData.append('body', this.text)
        for (let i = 0; i < this.files.length; i++) {
          formData.append('attachments[' + i + ']', this.files[i])
        }

        ApiService.post(`support/${this.ticket.id}/answers`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(({ data }) => {
            this.answers.push(data.data)

            this.text = ''
            this.file = []

            this.submitting = false
            this.$spinnerLoader.removeSpinnerLoader(submitButton)
            this.$bvModal.hide('ticket-modal')
            this.generateToast('success', this.$ml.get('addedAnswerSuccess'))
            this.$emit('getTickets')
          })
          .catch((err) => {
            this.submitting = false
            this.$spinnerLoader.removeSpinnerLoader(submitButton)
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
            console.log(err)
          })
      }
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
    isUserAnswer(answer) {
      return answer.owner.data.id == this.currentUser.user.id
    },
    getFileType(attachment) {
      const fileType = attachment.properties.mime_type.substr(
        attachment.properties.mime_type.lastIndexOf('/') + 1
      )
      return fileType
    },
    closeTicket() {
      this.loading = true
      ApiService.post(`support/${this.ticket.id}/solved`)
        .then(({ data }) => {
          this.$bvModal.hide('answer-modal')
          this.$generateToast(this, 'success', this.$ml.get('successful'))
          this.$emit('getTickets')
          this.loading = false
        })
        .catch((err) => {
          this.$generateToast(
            this,
            'danger',
            this.$ml.get('anErrorHasOccurred')
          )
          this.loading = false
        })
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    ticketAttachments() {
      return this.ticket && this.ticket.attachments
        ? this.ticket.attachments.data
        : []
    },
  },
  watch: {
    ticket: function () {
      this.isClosed = this.ticket.status.toLowerCase() == 'closed'
    },
  },
  directives: {
    autogrow: TextareaAutogrowDirective,
  },
  components: {
    ContentLoader,
  },
}
</script>

<style lang="scss">
#answer-modal {
  .modal-dialog {
    position: fixed;
    width: 20%;
    min-width: 750px;
    width: 100%;
    margin: 0;
    padding: 10px;
    bottom: 20px;
    right: 20px;
  }
  .modal-header {
    position: relative;
    display: block;
    background-color: white;
    border-bottom: 0;
    .badge {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 0;
      font-weight: 500;
      text-transform: capitalize;
      &.badge-closed {
        color: #8950fc;
        background: #eee5ff;
      }
      &.badge-open {
        color: #f64e60;
        background: #ffe2e5;
      }
      &.badge-pending {
        color: #ffa800;
        background: #fff4de;
      }
    }
    .ticketId {
      font-weight: 500;
    }
    .more-dropdown {
      button {
        border-radius: 0.28rem !important;
        height: 40px !important;
        &::after {
          content: '' !important;
          margin: 0px !important;
        }
      }
      .text-danger {
        a {
          color: #f64e60 !important;
        }
      }
    }
  }
  .down-icon {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    /* identical to box height */

    color: #b5b5c3 !important;
  }
  .modal-body {
    background-color: #f3f6f9;
    height: 60vh;
    overflow-y: auto;
    .chat {
      margin-bottom: 1rem;
      .avatar {
        flex: 0 0 50px;
        border-radius: 50%;
        overflow: hidden;
        align-self: flex-end;
        margin-bottom: 2rem;
      }
      .talk {
        width: 100%;
        flex: 1;
        .time {
          line-height: 2rem;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
          color: #b5b5c3;
        }
        .bubble {
          overflow-wrap: break-word;
          white-space: break-spaces;
          background: white;
          padding: 1rem;
          margin-left: 0.5rem;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          border-bottom-right-radius: 1rem;
          & + .time {
            text-align: left;
          }
        }
        .bubble-blue {
          overflow-wrap: break-word;
          white-space: break-spaces;
          background: #00baf2;
          color: white;
          padding: 1rem;
          margin-right: 0.5rem;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
          & + .time {
            text-align: right;
          }
        }
      }
    }
  }
  .modal-footer {
    border-top: 0;
    background-color: white;
    .custom-file.b-form-file {
      display: none;
    }
    .custom-txtarea {
      border: 0.5px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 12px;
      min-height: 60px;
      max-height: 600px;
    }
    .btn-custom-primary {
      background-color: #f3f6f9;
      color: #3699ff;
      i {
        color: #3699ff;
      }
      &:hover {
        background-color: #3699ff;
        color: #ffffff;
        i {
          color: #ffffff;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .modal-dialog {
      position: relative;
      width: 100%;
      min-width: 0px;
      right: 0;
      bottom: 0;
    }
    .modal-body {
      height: calc(100vh - 210px);
    }
  }
}
</style>
