<template>
  <div v-show="showOffcanvas">
    <div :class="['offcanvas offcanvas-right offcanvas-custom', { 'offcanvas-on' : show, 'offcanvas-off' : !show }]">
      <b-form class="d-flex flex-column h-100"
              @submit.stop.prevent="submitTicket">

        <div class="d-flex flex-column h-100">
          <!--begin::Header-->
          <div class="offcanvas-header d-flex align-items-center justify-content-between p-5">
            <h3 class="font-weight-bold m-0">
              {{ $ml.get('openTicket')}}
            </h3>
            <a href="#"
               class="d-flex btn btn-xs btn-light p-2 rounded-sm sidebar_close"
               @click="close">
              <i class="far fa-times-circle p-0"></i>
            </a>
          </div>
          <!--end::Header-->

          <div :disabled="loading">
            <div class="offcanvas-content pt-10 px-5">
              <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll"
                                 style="height: calc(100vh - 200px); position: relative">

                <div class="row">
                  <div class="col-12">
                    <b-form-group id="subjectGroup"
                                  :label="$ml.get('subject')"
                                  label-for="subject">
                      <b-form-input id="subject"
                                    v-model="ticket.subject"
                                    :placeholder="$ml.get('subject')"
                                    :state="validation"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <b-form-group id="levelGroup"
                                  :label="$ml.get('priority')"
                                  label-for="level">
                      <b-form-select id="level"
                                     class="rounded-sm"
                                     v-model="ticket.level"
                                     :options="levels"
                                     :state="validation">
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                                                disabled>{{
                    $ml.get('selectPriority')
                  }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <b-form-group id="categoryGroup"
                                  :label="$ml.get('category')"
                                  label-for="category">
                      <b-form-select id="category"
                                     class="rounded-sm"
                                     v-model="ticket.category"
                                     :state="validation">
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                                                disabled>{{
                    $ml.get('selectCategory')
                  }}</b-form-select-option>
                        </template>
                        <template v-for="category in categories">
                          <b-form-select-option :key="category.id"
                                                :value="category.id"
                                                @click="filterItemsCategory(category)">{{ category.name }}</b-form-select-option>
                          <b-form-select-option v-for="child in category.children"
                                                :key="child.id"
                                                :value="child.id"
                                                class="small"
                                                @click="filterItemsCategory(child)">- {{ child.name }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <b-form-group id="messageGroup"
                                  :label="$ml.get('message')"
                                  label-for="message">
                      <b-textarea id="message"
                                  class="rounded-sm"
                                  rows="5"
                                  v-model="ticket.message"
                                  :state="validation"></b-textarea>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12"
                       v-if="false">
                    <b-form-group id="fileGroup"
                                  :label="$ml.get('attachment')"
                                  label-for="file">
                      <b-form-file id="file"
                                   ref="file"
                                   multiple
                                   v-model="ticket.files"
                                   class="rounded-sm"
                                   :placeholder="$ml.get('chooseFile')"
                                   :drop-placeholder="$ml.get('dropFileHere')"></b-form-file>
                    </b-form-group>
                  </div>
                </div>

              </perfect-scrollbar>
            </div>
            <div class="mt-auto offcanvas-content pb-5 px-5">
              <div class="row">
                <div class="col-6 text-center">
                  <b-button type="reset"
                            class="w-100"
                            variant="danger"
                            @click="close">{{$ml.get('cancel')}}</b-button>

                </div>
                <div class="col-6 text-center">
                  <b-button type="submit"
                            class="w-100"
                            variant="primary"
                            ref="open_ticket">{{$ml.get('save')}}</b-button>
                </div>
              </div>

            </div>
          </div>
        </div>

      </b-form>

    </div>
    <div class="offcanvas-overlay"
         v-if="show"
         @click="close"></div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import EventBus from '@/event-bus'

export default {
  name: 'TicketOpenModal',
  props: ['categories'],
  data() {
    return {
      showOffcanvas: false,
      levels: [
        { text: this.$ml.get('low'), value: 'low' },
        { text: this.$ml.get('medium'), value: 'medium' },
        { text: this.$ml.get('high'), value: 'high' },
      ],
      ticket: {
        subject: '',
        level: null,
        category: null,
        message: '',
        files: [],
      },
      loading: false,
      validation: null,
      show: false,
    }
  },
  mounted() {
    EventBus.$on('supportTicketDetailOpen', (payload) => {
      this.show = payload
    })
  },
  created() {
    this.setShowOffcanvas()
  },
  methods: {
    setShowOffcanvas() {
      setTimeout(() => {
        this.showOffcanvas = true
      }, 1000)
    },
    submitTicket() {
      if ((this.validation = this.doValidation())) {
        this.loading = true

        const submitButton = this.$refs['open_ticket']
        this.$spinnerLoader.addSpinnerLoader(submitButton)

        let formData = new FormData()
        formData.append('title', this.ticket.subject)
        formData.append('body', this.ticket.message)
        formData.append('category_ref', this.ticket.category)
        formData.append('priority', this.ticket.level)
        for (let i = 0; i < this.ticket.files.length; i++) {
          formData.append('attachments[' + i + ']', this.ticket.files[i])
        }

        ApiService.post('support', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((res) => {
            this.ticket.subject = ''
            this.ticket.level = null
            this.ticket.category = null
            this.ticket.message = ''
            this.ticket.file = []
            this.validation = null

            this.loading = false
            this.$spinnerLoader.removeSpinnerLoader(submitButton)
            this.close()
            this.$generateToast(
              this,
              'success',
              this.$ml.get('addedTicketSuccess')
            )
            this.$emit('getTickets')
          })
          .catch((err) => {
            this.loading = false
            this.$spinnerLoader.removeSpinnerLoader(submitButton)
            this.$generateToast(
              this,
              'danger',
              this.$ml.get('anErrorHasOccurred')
            )
          })
      }
    },
    doValidation() {
      return (
        this.ticket.subject != '' &&
        this.ticket.level != null &&
        this.ticket.category != null &&
        this.ticket.subject != ''
      )
    },
    close() {
      this.show = false
    },
  },
}
</script> 
