<template>
  <div class="custom-pagination d-flex flex-column">
    <div class="d-flex justify-content-end align-items-center">
      <button type="button"
              @click="changePage(1)"
              class="navigation"
              :class="page==1 ? 'readonly':''"
              :readonly="page==1">
        <i class="far fa-chevron-double-left"></i> </button>

      <button type="button"
              @click="changePage(page-1)"
              class="navigation"
              :class="page==1 ? 'readonly':''"
              :readonly="page==1">
        <i class="far fa-chevron-left"></i> </button>

      <div v-for="item in getVisiblePages()"
           :key="item">
        <i class="fas fa-ellipsis-h dots"
           v-if="item=='..'"></i>
        <button type="button"
                v-else
                class="navigation"
                :class="page==item?'selected':''"
                @click="changePage(item)">
          {{item}}
        </button>
      </div>

      <button type="button"
              @click="changePage(page+1)"
              class="navigation"
              :class="page==pageCount ? 'readonly':''"
              :readonly="page==pageCount">
        <i class="far fa-chevron-right"></i> </button>

      <button type="button"
              @click="changePage(pageCount)"
              class="navigation"
              :class="page==pageCount ? 'readonly':''"
              :readonly="page==pageCount">
        <i class="far fa-chevron-double-right"></i> </button>

      <div class="goPage ml-5 mr-3 d-flex align-items-center">
        <div>
          {{ $ml.get('goToPage')}}
        </div>
        <div>
          <b-input-group class="ml-3">
            <b-form-input v-model="customPage"
                          class="customPageInput"
                          @keypress="pressControl($event)"></b-form-input>
            <b-input-group-append>
              <button type="button"
                      @click="changePage(customPage)"
                      class="navigation ml-0 customPageNavigation">
                <i class="far fa-chevron-right"></i> </button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center mt-3 totalRecords">
      {{$ml.get('totalRecord')}}: {{totalItems | countFix}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomPagination',
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      customPage: '',
    }
  },
  methods: {
    changePage(val) {
      if (val > 0 && val <= this.pageCount && val != this.page && val != '..')
        this.$emit('changePage', parseInt(val))
    },
    getVisiblePages() {
      let l = []
      let startCount = 1
      let endCount = this.pageCount
      if (this.pageCount <= 5) {
        startCount = 1
        endCount = this.pageCount
      } else {
        startCount =
          this.page < this.pageCount ? this.page - 1 : this.pageCount - 2

        if (this.page > 1)
          endCount =
            this.page + 1 < this.pageCount ? this.page + 1 : this.pageCount
        else endCount = 3
      }

      let addedDot = false

      l.push(1)
      for (let index = 2; index < this.pageCount; index++) {
        if (index >= startCount && index <= endCount) {
          l.push(index)
          addedDot = false
        } else if (!addedDot) {
          l.push('..')
          addedDot = true
        }
      }
      if (this.pageCount > 1) l.push(this.pageCount)
      return l
    },
    pressControl: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode

      if (charCode === 13) {
        this.changePage(this.customPage)
      }

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
  filters: {
    countFix(val) {
      if (val || val == 0) {
        val = val.toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      } else return '-'
    },
  },
}
</script>

<style lang="scss"  scoped>
.custom-pagination {
  padding: 20px 0px;
  .navigation {
    background: #ffffff !important;
    border: 1px solid #e5eaee !important;
    border-radius: 6px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #b5b5c3 !important;
    height: 26px !important;
    width: 26px !important ;
    min-width: 26px !important ;
    box-shadow: none !important;
    margin-left: 5px;
    i {
      font-size: 12px !important;
    }
    &:hover,
    &.selected {
      background: #eee5ff !important;
      color: #00baf2 !important;
    }
    &.readonly {
      cursor: not-allowed;
    }
  }
  .dots {
    color: #e5eaee !important;
    margin-left: 5px;
  }
  .goPage {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #b5b5c3;
    .customPageInput {
      background: #ffffff;
      border: 1px solid #e5eaee;
      box-sizing: border-box;
      border-radius: 6px;
      height: 26px;
      width: 18px;
      padding: 6px 4px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #b5b5c3;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
    .customPageNavigation {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }
  .totalRecords {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #00baf2;
  }
}
</style>