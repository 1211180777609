<template>
  <div>
    <!--begin::Support-->
    <b-card no-body
            class="support-filter px-5 py-2 mb-5">
      <div class="d-flex align-items-center filter-collapse"
           :disabled="loading">

        <div class="d-flex flex-row-reverse align-items-center d-md-none cursor-pointer">
          <i class="fas fa-filter"
             :class="this.mobileVisible ? ' mb-2':''"
             @click="changeOnmobileVisible()"></i>
        </div>
        <div class="w-100 d-md-flex align-items-center"
             :class="this.mobileVisible ? '':' d-none'">
          <h3 class="text-dark font-size-secondary m-0 mr-5">{{$ml.get('filters')}}</h3>
          <b-button :variant="statusFilters=='all'?'filter-success':'filter-muted'"
                    @click="changeStatusFilter('all')"
                    class="rounded-sm">{{ $ml.get('allTickets') }}</b-button>
          <b-button :variant="statusFilters=='open'?'filter-success':'filter-muted'"
                    @click="changeStatusFilter('open')"
                    class="rounded-sm ml-2">{{ $ml.get('open') }}</b-button>
          <b-button :variant="statusFilters=='pending'?'filter-success':'filter-muted'"
                    @click="changeStatusFilter('pending')"
                    class="rounded-sm ml-2">{{ $ml.get('pending') }}</b-button>
          <b-button :variant="statusFilters=='closed'?'filter-success':'filter-muted'"
                    @click="changeStatusFilter('closed')"
                    class="rounded-sm ml-2">{{ $ml.get('closed') }}</b-button>

          <hr class="filter-divider-custom" />

          <b-dropdown id="dropdown-action"
                      variant="filter-success"
                      :text="$ml.get(priorityFilter.name)"
                      class="ml-2 mobile-100">
            <template v-for="priority in priorityList">
              <b-dropdown-item :key="priority.value"
                               @click="filterItemsPriority(priority)">{{ $ml.get(priority.name) | titleCase }}</b-dropdown-item>
            </template>
          </b-dropdown>
          <hr class="filter-divider-custom" />

          <b-dropdown id="dropdown-action"
                      variant="filter-success"
                      :text="categoryFilter.name"
                      :disabled="loadingCategories"
                      class="ml-2 mobile-100">
            <b-dropdown-item href="#"
                             @click="
              filterItemsCategory({
                id: '',
                name: $ml.get('allCategories'),
              })
            ">{{ $ml.get('allCategories') }}</b-dropdown-item>
            <template v-for="category in categories">
              <b-dropdown-item :key="category.id"
                               @click="filterItemsCategory(category)">{{ category.name }}</b-dropdown-item>
              <b-dropdown-item v-for="child in category.children"
                               :key="child.id"
                               class="small"
                               @click="filterItemsCategory(child)">{{ child.name }}</b-dropdown-item>
            </template>
          </b-dropdown>

          <hr class="filter-divider-custom" />

          <b-dropdown id="dropdown-action"
                      variant="filter-success"
                      :text="$ml.get('dateFilter')"
                      class="mobile-100"
                      ref="dateFilterDropdown">
            <b-dropdown-form>
              <b-form-group :label="$ml.get('startDate')"
                            label-for="dropdown-form-startdate"
                            @submit.stop.prevent>
                <b-form-input id="dropdown-form-startdate"
                              size="sm"
                              v-model="startDateFilter"
                              v-mask="['##/##/####']"
                              :placeholder="$ml.get('enterStartDate')"></b-form-input>
              </b-form-group>

              <b-form-group :label="$ml.get('endDate')"
                            label-for="dropdown-form-enddate"
                            @submit.stop.prevent>
                <b-form-input id="dropdown-form-enddate"
                              size="sm"
                              v-model="endDateFilter"
                              v-mask="['##/##/####']"
                              :placeholder="$ml.get('enterEndDate')"></b-form-input>
              </b-form-group>
              <div class="d-flex mt-5">
                <a href="#"
                   class="d-flex btn btn-sm rounded-sm pr-15 pl-15 mr-5 btn-danger"
                   @click="dateFilterClear()">
                  {{ $ml.get('clear') }}
                </a>
                <a href="#"
                   @click="dateFilterSubmit()"
                   class="d-flex btn btn-sm rounded-sm pr-15 pl-15 btn-primary">
                  {{ $ml.get('search') }}
                </a>
              </div>
            </b-dropdown-form>
          </b-dropdown>

        </div>
      </div>
    </b-card>
    <Card class="support-list">
      <template #header>
        <div class="row p-0 m-0 w-100">
          <div class="title py-5 col-md-8">
            <h3 class="text-dark font-size-secondary m-0">
              {{ $ml.get('myTickets') }}
            </h3>
            <p class="font-size-md text-muted mt-2">
              {{ $ml.get('latestActivityOnYourAccountIsDisplayed') }}
            </p>
          </div>
        </div>
      </template>
      <!-- :custom-filter="customFilter" -->
      <v-data-table :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :items-per-page="perPage"
                    :server-items-length="totalItems"
                    :loading="loading"
                    @update:page="changePagination"
                    class="row-pointer"
                    :page.sync="page"
                    @page-count="pageCount = $event"
                    @click:row="handleRowClick"
                    hide-default-footer>

        <template v-slot:header.priority>
          {{$ml.get('priority')}}
        </template>
        <template v-slot:header.id>

          <b-dropdown class="dropdown-detail"
                      no-caret
                      right>
            <template #button-content>
              {{$ml.get('ticketNumber')}}
            </template>
            <b-dropdown-text>
              <div class="form-search-input">
                <v-text-field class="my-0 font-size-sm"
                              :placeholder="$ml.get('searchForTicketNumber')"
                              prepend-icon="search"
                              v-model="searchId"
                              single-line
                              dense
                              flat
                              solo
                              hide-details></v-text-field>
              </div>
            </b-dropdown-text>
          </b-dropdown>

        </template>
        <template v-slot:header.title>
          {{$ml.get('title')}}
        </template>
        <template v-slot:header.category.data.name>
          {{$ml.get('category')}}
        </template>
        <template v-slot:header.created_at>
          {{$ml.get('createdDate')}}
        </template>
        <template v-slot:header.status>
          {{$ml.get('status')}}
        </template>
        <template v-slot:header.action>
          {{$ml.get('actions')}}
        </template>
        <template v-slot:item.priority="{ item }">
          <span class="priority"
                :inner-html.prop="item.priority | filterPriority"></span>
        </template>
        <template v-slot:item.title="{ item }">
          <div v-html="item.title"></div>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{$customFunctions.setMomentWithFormat(item.created_at,'L LT')}}
        </template>
        <template v-slot:item.status="{ item }">
          <b-badge class="rounded-sm"
                   :class="'badge-' + item.status.toLowerCase()">{{ item.status.toLowerCase() }}</b-badge>
        </template>
        <template v-slot:item.action="{ item }">
          <b-dropdown variant="success"
                      class="btn-icon more-dropdown"
                      right>
            <template v-slot:button-content><i class="fas fa-cog p-0"></i></i>
            </template>
            <b-dropdown-item @click.native.stop
                             @click="handleRowClick(item)">{{$ml.get('showSupportDetail')}}</b-dropdown-item>
            <b-dropdown-divider v-if="item.status.toLowerCase()!='closed'" />
            <b-dropdown-item @click.native.stop
                             @click="closeTicket(item.id)"
                             v-if="item.status.toLowerCase()!='closed'"
                             class="text-danger">{{$ml.get('closeTicket')}}</b-dropdown-item>
          </b-dropdown>
        </template>
      </v-data-table>
    </Card>
    <CustomPagination :page="page"
                      :pageCount="pageCount"
                      :totalItems="totalItems"
                      @changePage="changePage" />
    <TicketAnswerModal :ticket="selectedTicket"
                       @getTickets="getTickets"></TicketAnswerModal>

    <TicketOpenModal :categories="categories"
                     @getTickets="getTickets"></TicketOpenModal>

    <!--end::Support-->

    <div class="page-content-loading"
         v-if="pageContentLoading">
      <span class="spinner spinner-light spinner-center"></span>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import moment from 'moment'
import Card from '@/components/Card'
import TicketOpenModal from '@/components/support/TicketOpenModal'
import TicketAnswerModal from '@/components/support/TicketAnswerModal'
import CustomPagination from '@/components/CustomPagination'
import { SET_CUSTOMHEADERPARAMS } from '@/core/services/store/customsubheader.module'
import EventBus from '@/event-bus'

var getTicketsInterval

export default {
  name: 'support',
  data() {
    return {
      selectedTicket: {},
      search: '',
      searchId: '',
      pageContentLoading: false,
      loading: false,
      loadingCategories: false,
      options: {},
      perPage: 16,
      totalItems: 0,
      items: [],
      itemsCount: 0,
      page: 1,
      pageCount: 0,
      headers: [
        { text: this.$ml.get('priority'), value: 'priority', sortable: false },
        { text: this.$ml.get('ticketNumber'), value: 'id', sortable: false },
        { text: this.$ml.get('title'), value: 'title', sortable: false },
        {
          text: this.$ml.get('category'),
          value: 'category.data.name',
          sortable: false,
        },
        {
          text: this.$ml.get('createdDate'),
          value: 'created_at',
          sortable: false,
        },
        { text: this.$ml.get('status'), value: 'status', sortable: false },
        {
          text: this.$ml.get('actions'),
          value: 'action',
          sortable: false,
          align: 'right',
        },
      ],
      categoryFilter: {
        id: '',
        name: this.$ml.get('allCategories'),
      },
      categories: [],
      statusFilters: 'all',
      priorityFilter: {
        id: 'all',
        name: 'allPriorities',
      },
      priorityList: [
        { name: 'allPriorities', value: 'all' },
        { name: 'low', value: 'LOW' },
        { name: 'medium', value: 'MEDIUM' },
        { name: 'high', value: 'HIGH' },
      ],
      startDateFilter: '',
      endDateFilter: '',
      mobileVisible: false,
      resetPage: false,
    }
  },
  mounted() {
    EventBus.$on('supportSearch', (payload) => {
      this.search = payload
    })
  },
  created() {
    this.$store.dispatch(SET_CUSTOMHEADERPARAMS, {
      showComponentName: 'SupportSubHeader',
    })

    if (this.$route.hash) {
      // Clear answer modal
      this.$bvModal.hide('answer-modal')
      // Get Ticket
      this.getTicketID(this.$route.hash)
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$ml.get('support'), route: 'support' },
    ])

    this.getCategories()
      .then((res) => {
        this.setCategories(res)
      })
      .catch((err) => {
        console.log(err)
      })

    this.getTickets()
    this.startTicketInterval('created')
  },
  destroyed() {
    clearInterval(getTicketsInterval)
  },
  methods: {
    getTicketList() {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        let url = `support?position=id|DESC&page=${
          page ? page : 1
        }&include=creator.masterAccount`

        if (this.statusFilters !== undefined && this.statusFilters !== 'all') {
          if (this.resetPage) {
            this.options.page = 1
            this.resetPage = false
          }
          url += `&status=${this.statusFilters}`
        }

        if (this.search !== '') {
          if (this.resetPage) {
            this.options.page = 1
            this.resetPage = false
          }
          url += `&title=${this.search}`
        }

        if (this.searchId !== '') {
          if (this.resetPage) {
            this.options.page = 1
            this.resetPage = false
          }
          url += `&id=${this.searchId}`
        }

        if (
          this.categoryFilter.id !== undefined &&
          this.categoryFilter.id !== ''
        ) {
          if (this.resetPage) {
            this.options.page = 1
            this.resetPage = false
          }
          url += `&category=${this.categoryFilter.id}`
        }

        if (
          this.priorityFilter.value !== undefined &&
          this.priorityFilter.value !== 'all'
        ) {
          if (this.resetPage) {
            this.options.page = 1
            this.resetPage = false
          }
          url += `&priority=${this.priorityFilter.value}`
        }

        if (this.startDateFilter !== '') {
          if (this.resetPage) {
            this.options.page = 1
            this.resetPage = false
          }
          url += `&start=${moment(this.startDateFilter, 'MM/DD/YYYY').format(
            'YYYY-MM-DD'
          )}`
        }

        if (this.endDateFilter !== '') {
          if (this.resetPage) {
            this.options.page = 1
            this.resetPage = false
          }
          url += `&end=${moment(this.endDateFilter, 'MM/DD/YYYY').format(
            'YYYY-MM-DD'
          )}`
        }

        ApiService.get(url)
          .then(({ data }) => {
            this.loading = false
            resolve(data)
          })
          .catch((err) => {
            this.loading = false
            reject(err)
          })
      })
    },
    setTicketData(res) {
      this.items = res.data
      this.totalItems = res.meta.pagination.total
      this.perPage = res.meta.pagination.count
    },
    startTicketInterval(whereStart) {
      getTicketsInterval = setInterval(() => {
        ApiService.get('support').then(({ data }) => {
          // SetInterval first request and set itemsCount
          if (whereStart == 'created') {
            whereStart = ''
            this.itemsCount = data.meta.pagination.total
          }

          // Check tickets count
          if (
            this.itemsCount > 0 &&
            data.meta.pagination.total > this.itemsCount
          ) {
            // Show New ticket available alert
            this.$generateToast(
              this,
              'success',
              this.$ml.get('newTicketsAvailable'),
              false,
              this.$ml.get('newTicket')
            )

            // Get new tickets
            this.getTickets()

            // Set up to date itemsCount
            this.itemsCount = data.meta.pagination.total
          }
        })
      }, 60000) // 1 minute
    },
    getTickets() {
      this.getTicketList()
        .then((res) => {
          this.setTicketData(res)
        })
        .catch((err) => {
          this.items = []
          console.log(err)
        })
    },
    getCategories() {
      this.loadingCategories = true
      return new Promise((resolve, reject) => {
        ApiService.get('categories')
          .then(({ data }) => {
            this.loadingCategories = false
            resolve(data)
          })
          .catch((err) => {
            this.loadingCategories = false
            reject(err)
          })
      })
    },
    setCategories(res) {
      this.categories = res.data
    },
    changePagination() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      this.getTickets()
    },
    changeStatusFilter(filter) {
      this.statusFilters = filter
    },
    filterItemsCategory(category) {
      this.categoryFilter = category
    },
    filterItemsPriority(priority) {
      this.priorityFilter = priority
    },
    dateFilterClear() {
      this.resetPage = true
      this.startDateFilter = ''
      this.endDateFilter = ''
    },
    dateFilterSubmit() {
      this.resetPage = true
      this.$refs.dateFilterDropdown.hide(true)
      this.getTickets()
    },
    // customFilter(items, search, filter) {
    //     search = search.toString().toLowerCase()
    //     return items.filter(row => filter(row["type"], search));
    // }
    handleRowClick(item) {
      this.selectedTicket = item
      this.$bvModal.show('answer-modal')
    },
    changeOnmobileVisible() {
      this.mobileVisible = !this.mobileVisible
    },
    getTicketID(toHash) {
      this.pageContentLoading = true

      if (toHash.charAt(0) == '#') {
        ApiService.get(`support/${toHash.substring(1)}`)
          .then(({ data }) => {
            this.selectedTicket = data.data
            this.$bvModal.show('answer-modal')

            this.pageContentLoading = false
          })
          .catch((err) => {
            console.error(err)
            this.pageContentLoading = false
          })
      }
    },
    changePage(value) {
      this.page = value
    },
    closeTicket(id) {
      this.loading = true
      ApiService.post(`support/${id}/solved`)
        .then(({ data }) => {
          this.$generateToast(this, 'success', this.$ml.get('successful'))
          this.getTickets()
        })
        .catch((err) => {
          this.$generateToast(
            this,
            'danger',
            this.$ml.get('anErrorHasOccurred')
          )
          this.loading = false
        })
    },
  },
  filters: {
    filterPriority(priority) {
      switch (priority) {
        case 'LOW':
          return priority.toLowerCase() + '<i class="fas fa-arrow-down"></i>'
        case 'HIGH':
          return priority.toLowerCase() + '<i class="fas fa-arrow-up"></i>'
        default:
          return priority.toLowerCase() + '<i class="fas fa-minus"></i>'
      }
    },
    titleCase(str) {
      return str
        .split(' ')
        .map(
          (item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
        )
        .join(' ')
    },
  },
  watch: {
    $route(to) {
      if (to.hash) {
        // Clear answer modal
        this.$bvModal.hide('answer-modal')
        // Get Ticket
        this.getTicketID(to.hash)
      }
    },
    statusFilter: function () {
      this.resetPage = true
      this.getTickets()
    },
    categoryFilter: function () {
      this.resetPage = true
      this.getTickets()
    },
    priorityFilter: function () {
      this.resetPage = true
      this.getTickets()
    },
    search: function () {
      this.resetPage = true
      this.getTickets()
    },
    searchId: function () {
      this.resetPage = true
      this.getTickets()
    },
    statusFilters: function () {
      this.resetPage = true
      this.getTickets()
    },
    // options: {
    //   handler() {
    //     this.getTicketList().then((res) => {
    //       this.setTicketData(res)
    //     })
    //   },
    //   deep: true,
    // },
  },
  components: {
    Card,
    TicketOpenModal,
    TicketAnswerModal,
    CustomPagination,
    moment,
  },
}
</script>

<style lang="scss">
.support-filter {
  #dropdown-action {
    .btn.dropdown-toggle {
      border-radius: 0.28rem;
    }
  }
  .dropdown-item {
    padding: 0.25rem 1.25rem;
  }
  .small {
    padding-left: 0.5rem;
  }
  .b-dropdown-form {
    min-width: 320px;
  }
}
.support-list {
  .badge {
    font-weight: 500;
    text-transform: capitalize;
    &.badge-closed {
      color: #8950fc;
      background: #eee5ff;
    }
    &.badge-open {
      color: #f64e60;
      background: #ffe2e5;
    }
    &.badge-pending {
      color: #ffa800;
      background: #fff4de;
    }
  }
  .priority {
    font-weight: 500;
    text-transform: capitalize;
    i {
      font-size: 0.8rem;
      margin-left: 0.25rem;
    }
    .fa-arrow-down {
      color: #1bc5bd;
    }
    .fa-arrow-up {
      color: #f64e60;
    }
    .fa-minus {
      color: #ffa800;
    }
  }
  .more-dropdown {
    button {
      border-radius: 0.28rem !important;
      width: 32px !important;
      height: 32px !important;
      padding: 0 !important;
      &::after {
        content: '' !important;
        margin: 0px !important;
      }
    }
    .text-danger {
      a {
        color: #f64e60 !important;
      }
    }
  }

  .dropdown-detail {
    button {
      border: none !important;
      width: 37px !important;
      height: 35px !important;
      padding: 0 0 0 5px;
      border-radius: 4px !important;
      background-color: #fff !important;
      font-size: 0.75rem;
      color: rgb(181, 181, 195) !important;
      font-weight: 700;
      &:hover {
        background-color: #fff !important;
        color: rgb(181, 181, 195) !important;
        text-decoration: underline;
      }
    }
    ul {
      width: 250px !important;
    }
    .form-search-input,
    .v-input__slot,
    input {
      background: #fff !important;
    }
    .form-search-input {
      padding-left: 0px !important;
    }
  }
}
@media (max-width: 767px) {
  .support-filter {
    .filter-collapse {
      display: block !important;
      .mobile-100 {
        width: 100% !important;
        margin-left: 0px !important;
        margin-bottom: 5px !important;
      }
    }
  }
  .support-list {
    .buttons {
      width: 100% !important;
    }
  }
}

.page-content-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner:before {
    width: 3rem;
    height: 3rem;
    margin-top: -1.5rem;
  }

  .spinner.spinner-light:before {
    border: 3px solid #f3f6f9;
    border-right: 3px solid transparent;
  }
}
</style>
